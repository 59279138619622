import { render, staticRenderFns } from "./table-product-comparation.vue?vue&type=template&id=087eacb0&scoped=true&"
import script from "./table-product-comparation.vue?vue&type=script&lang=js&"
export * from "./table-product-comparation.vue?vue&type=script&lang=js&"
import style0 from "./table-product-comparation.vue?vue&type=style&index=0&id=087eacb0&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "087eacb0",
  null
  
)

export default component.exports

/* chakra-loader */
const installChakraComponents = require("!../../node_modules/chakra-loader/lib/install-components.js")
installChakraComponents(component, {CText: require('@chakra-ui/vue').CText, CFlex: require('@chakra-ui/vue').CFlex, CBox: require('@chakra-ui/vue').CBox, CGrid: require('@chakra-ui/vue').CGrid})
